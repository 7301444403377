@import 'src/assets/styles/_variables.scss';

.app-container {
    background-color: #F7F7F7;
    min-height: 100vh;
}

.content {
    //padding: 20px;
}

.header {
    @include center-objects;
    height: 64px;
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid $light-grey;
    position: relative;
    // Remove the padding-left from here

    .active {
        font-weight: 600;
        color: $blue;
        border-bottom: 2px solid $blue;
    }
}

.page-btn {
    @include center-objects;
    cursor: pointer;
    height: 100%;
    color: $light-grey;
    width: 18%;
}

.blue-link {
    color: $blue;
}

.loading {
    @include center-objects;
    flex-direction: column; // Add this line
    width: 100%;
    height: 100vh;
    color: $blue;
}

.ai-see-logo {
    width: 110px; // Increased from 100px to 110px (10% larger)
    height: auto;
}

.desktop-logo {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none; // Hide by default
}

.loading-logo {
    margin-top: 20px; // Add some space between the spinner and the logo
}

// Media query for desktop and tablets
@media (min-width: 768px) {
    .desktop-logo {
        display: block; // Show on desktop and tablets
    }

    .header {
        padding-left: 140px; // Apply padding only for desktop and tablets
    }
}

.logo {
    width: 150px;
    display: block;
  margin-left: auto;
  margin-right: auto;
}