@import 'src/assets/styles/_variables.scss';

.capture {
    width: 90%;
    margin: 1.5rem auto;
    position: relative;

    h3 {
        font-weight: 600;
        font-size: $headline-size;
        color: $black;
        margin: 0;
    }

    .video {
        position: relative;
        width: 100%;
        height: 85vh;
        margin-bottom: 1rem;

        .video-frame {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $main-border-radius;
        }

        .classes-dropdown,
        .source-dropdown {
            position: absolute;
            top: 1rem;
            z-index: 10;
            background: transparent !important;

            &.show {
                background: transparent !important;
            }

            .dropdown-toggle {
                background-color: transparent;
                color: $white;
                font-size: $small-text-size;
                padding: 0.1rem 1rem;
                border-radius: $main-border-radius;
                border: 1px solid rgba($white, 0.3);
                background-clip: padding-box;
                height: 38px; // Set a fixed height for both dropdowns
                display: flex;
                align-items: center;

                &:hover, &:focus {
                    background-color: rgba($white, 0.1);
                }

                span {
                    background-color: transparent;
                }
            }

            .dropdown-menu {
                background-color: rgba($dark-grey, 0.9);
                border: none;
                border-radius: $main-border-radius;
                margin-top: 0.5rem;

                .dropdown-item {
                    color: $white;

                    &:hover, &:focus {
                        background-color: rgba($blue, 0.2);
                    }

                    &.active {
                        background-color: $blue;
                    }
                }
            }
        }

        .classes-dropdown {
            left: 1rem;
        }

        .source-dropdown {
            right: 1rem;
        }

        p {
            color: $dark-grey;
            font-size: $main-text-size;
            margin-bottom: 1rem;
            position: absolute;
            background: rgba(255, 255, 255, 0.1);
            color: $white;
            margin: 0.5rem;
        }

        .video-frame {
            width: 100%;
            min-height: 200px;
            object-fit: cover;
            border-radius: 6px 6px 0 0;
        }

        .last-frames-overlay {
            position: absolute;
            bottom: 3rem;
            right: 1rem;
            background-color: rgba(#000, 0.2);
            border-radius: $main-border-radius;
            padding: 0.75rem;
            z-index: 10;
            max-height: 50vh;
            max-width: 80%;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);

            .overlay-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.5rem;
                flex-shrink: 0;

                h4 {
                    color: $white;
                    font-size: $small-text-size;
                    margin: 0;
                    background-color: transparent;
                }

                .total-label {
                    color: $white;
                    font-size: $small-text-size;
                    margin: 0;
                    background-color: transparent;
                    position: absolute;
                    right: 0.75rem;
                }
            }

            .frames__photos.scrollable {
                overflow-x: auto;
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding-bottom: 10px;

                &::-webkit-scrollbar {
                    height: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: rgba(0, 0, 0, 0.1);
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(0, 0, 0, 0.3);
                    border-radius: 3px;
                }

                .photo-wrapper {
                    width: 60px;
                    height: 60px;
                    position: relative;
                    flex-shrink: 0;
                    margin-top: 10px;
                    overflow: hidden;
                    border-radius: $main-border-radius;

                    .photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    .delete-wrapper {
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 20px;
                        width: 20px;
                        border-radius: 0 $main-border-radius 0 $main-border-radius;
                        background: rgba(0, 0, 0, 0.6);
                        @include center-objects;
                        z-index: 999;
                        cursor: pointer;
                        transition: background-color 0.2s ease;

                        &:hover {
                            background: rgba(0, 0, 0, 0.8);
                        }

                        i {
                            font-size: 14px;
                            color: $white;
                            @include center-objects;
                        }
                    }
                }

                .no-images {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    color: $white;
                    padding: 1rem 0;

                    i {
                        font-size: 1.5rem;
            
                    }

                    p {
                        font-size: $small-text-size;
                        text-align: center;
                        margin: 0;
                        background: transparent;
                        position: static;
                    }
                }
            }

            .frames__photos.scrollable::-webkit-scrollbar {
                height: 6px;
            }

            .frames__photos.scrollable::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.1);
            }

            .frames__photos.scrollable::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.3);
                border-radius: 3px;
            }
        }
    }

    .take-photo {
        @include center-objects;
        z-index: 999;
        margin: -3rem 0 2rem;
        background: $white;
        position: relative;
        border-radius: $main-border-radius;
        box-shadow: 0px -3px 25px 0px rgba(9, 13, 55, 0.15);

        button {
            width: 30%;
            color: $black;

            p {
                cursor: pointer;
                font-size: $small-text-size;
                background: $white;
            }
        }

        .active {
            font-weight: 600;
            color: $blue;
        }

        .inactive {
            color: $grey;
            font-weight: normal;

            p {
                cursor: default;
            }
        }

        &__wrapper {
            @include center-objects;
            background: $white;
            width: 55px;
            height: 55px;
            border-radius: 50%;

            img {
                cursor: pointer;
                border-radius: 50%;
                margin: -1.5rem 0 0 0;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -o-user-select: none;
                user-select: none;
                pointer-events: none;
            }
        }
    }

    .dropdown {
        justify-content: start;

        .dropdown-menu {
            background: $dark-grey;
            border: 1px solid $light-grey;
            border-radius: $main-border-radius;
            padding: 0.5rem 0;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

            .dropdown-item {
                margin-bottom: 0;
                background: transparent;
                color: $white;
                border-radius: 0;
                padding: 0.5rem 1rem;
                transition: background-color 0.2s ease;

                &:hover, &:focus {
                    background-color: rgba($blue, 0.2);
                }

                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
        }

        .dropdown-toggle {
            background-color: $blue;
            border-color: $blue;
            color: $white;
            font-size: $small-text-size;
            padding: 0.5rem 1rem;
            border-radius: $main-border-radius;

            &:hover, &:focus {
                background-color: darken($blue, 10%);
                border-color: darken($blue, 10%);
            }
        }
    }

    .classes-dropdown {
        button {
            height: 30px;
            font-size: $small-text-size;
        }

        .dropdown-menu {
            max-height: 200px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: $dark-grey;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $light-grey;
                border-radius: 4px;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .video {
        position: relative;
        width: 100%;
        height: 80vh;
        margin-bottom: 1rem;

        .flash-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            opacity: 0;

            pointer-events: none;
            z-index: 10;
        }

        .flash-overlay.flashing {
            animation: flash 0.1s ease-out;
        }
    }

    @keyframes flash {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}