@import 'src/assets/styles/_variables.scss';

.button-style {
    @include center-objects;
    width: 100%;
    height: 50px;
    border-radius: $main-border-radius;
    background: rgba(42, 109, 255, 0.20);

    p {
        background: none;
    }
}

.primary {
    background: $blue;
    color: $white;
}

.secondary {
    background: none;
    color: $dark-grey;
}

.disabled {
    opacity: 0.6;
}