@import 'src/assets/styles/_variables.scss';

.setup {
    max-width: 800px;
    margin: 1rem auto;
    padding: 0 1rem;

    .panel {
        background: $white;
        border-radius: $main-border-radius;
        padding: 1.5rem;
        margin-bottom: 1.5rem;

        h2 {
            font-weight: 600;
            font-size: $headline-size;
            margin: 0 0 0.5rem;
            color: $black;
        }

        .description {
            color: $grey;
            font-size: $main-text-size;
            margin-bottom: 1.5rem;
        }
    }

    .classes {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .class-item {
        display: flex;
        align-items: center;
        background: $ultra-light-grey;
        border-radius: $main-border-radius;
        padding: 0.75rem;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
            background: darken($ultra-light-grey, 5%);
        }

        &.active {
            border: 2px solid $blue;
        }

        &__image {
            width: 60px;
            height: 60px;
            border-radius: $main-border-radius;
            overflow: hidden;
            margin-right: 1rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__info {
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;

            input {
                width: 100%;
                font-size: 1.1rem;
                font-weight: 600;
                color: $black;
                background: transparent;
                border: none;
                padding: 0.25rem;
                margin-bottom: 0.25rem;
                border-radius: $main-border-radius;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:focus {
                    background: $white;
                    outline: none;
                    white-space: normal;
                    overflow: visible;
                }
            }

            .amount-photo {
                font-size: $small-text-size;
                color: $grey;
                margin: 0;
            }
        }

        .delete-btn {
            background: none;
            border: none;
            color: $grey;
            font-size: 1.2rem;
            cursor: pointer;
            padding: 0.5rem;
            margin-left: 1rem;
            transition: color 0.3s ease;

            &:hover {
                color: $red;
            }
        }
    }

    .add-class {
        margin-top: 1.5rem;
        display: flex;
        justify-content: flex-start;
    }

    .navigation-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }

    button {
        padding: 0.75rem 1rem;
        font-size: $main-text-size;
        font-weight: 600;
        border-radius: $main-border-radius;
        transition: all 0.3s ease;
        border: none;
        cursor: pointer;
        min-width: 150px;
        max-width: 200px;

        &.primary {
            background: linear-gradient(90deg, $blue, lighten($blue, 10%));
            color: $white;

            &:hover {
                background: linear-gradient(90deg, darken($blue, 10%), $blue);
            }

            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }

        &.fancy {
            background: rgba($blue, 0.1);
            color: $blue;
            border: 2px solid $blue;

            &:hover {
                background: rgba($blue, 0.2);
            }
        }
    }
}

@media (min-width: 768px) {
    .setup {
        .panel {
            padding: 2rem;
        }

        .class-item {
            &__image {
                width: 70px;
                height: 70px;
                min-width: 70px;
            }

            &__info {
                input {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .setup {
        max-width: 900px;

        .classes {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
        }
    }
}
