/*-- colors --*/

$background-color: #f7f9fa;
$black: #090D37;
$white: #FFF;
$blue: rgb(42, 131, 255);
$light-blue:rgba(42, 131, 255, 0.1);
$red: #FF4747;
$light-red: #FFE7E7;
$ultra-light-grey: #ececec;
$light-grey: #CACACA;
$grey: #BABABF;
$dark-grey: #7F818D;

/*-- sizes --*/
$headline-size: 18px;
$main-text-size: 14px;
$small-text-size: 12px;
$big-text-size: 16px;

$main-border-radius: 6px;

/*-- center mixin --*/

@mixin center-objects {
    display: flex;
    justify-content: space-around;
    align-items: center;
}