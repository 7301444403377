@import 'src/assets/styles/_variables.scss';

.predict {
    width: 90%;
    margin: 1.5rem auto;

    h3 {
        font-weight: 600;
        color: $black;
        font-size: $headline-size;
        margin: 0;
    }

    .video-container {
        position: relative;
        width: 100%;
        height: 85vh;
        margin-bottom: 1rem;

        .source-dropdown {
            position: absolute;
            top: 1rem;
            left: 1rem; // Changed from right to left
            z-index: 10;

            .dropdown-toggle {
                background-color: rgba($dark-grey, 0.7);
                border: none;
                color: $white;
                font-size: $small-text-size;
                padding: 0.5rem 1rem;
                border-radius: $main-border-radius;

                &:hover, &:focus {
                    background-color: rgba($dark-grey, 0.9);
                }
            }

            .dropdown-menu {
                background-color: rgba($dark-grey, 0.9);
                border: none;
                border-radius: $main-border-radius;
                margin-top: 0.5rem;

                .dropdown-item {
                    color: $white;

                    &:hover, &:focus {
                        background-color: rgba($blue, 0.2);
                    }

                    &.active {
                        background-color: $blue;
                    }
                }
            }
        }
    }

    .video {
        position: relative;
        width: 100%;
        height: 85vh; // This makes the video container 75% of the viewport height
        margin-bottom: 1rem;

        margin: 1.25rem auto;

        p {
            color: $dark-grey;
            font-size: $main-text-size;
            margin-bottom: 1rem;
        }

        .video-frame {
            width: 100%;
            height: 100%; // This ensures the video frame fills its container
            object-fit: cover;
            border-radius: $main-border-radius;
        }
    }

    .classes-overlay {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 300px;
        max-height: calc(100% - 40px);
        overflow-y: auto;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
        border-radius: $main-border-radius;
        padding: 15px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.3);
       

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
        }
    }

    .classes {
        margin: 1.25rem auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .class-item {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        gap: 0.5rem;
        height: 62px;
        width: 100%;
        border-radius: $main-border-radius;
        border: 1px solid rgba(202, 202, 202, 0.30);
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(5px);
        transition: all 0.3s ease;
        margin-bottom: 0;

        &:hover {
            background: rgba(255, 255, 255, 0.8);
            transform: translateY(-2px);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }

        &>img {
            width: 45px;
            height: 45px;
            border-radius: $main-border-radius;
            object-fit: cover;
            @include center-objects;
            color: $dark-grey;
            border: 1px solid rgba(202, 202, 202, 0.30);

        }

        &__info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            background-color: $white;


            .info {
                display: flex;
                justify-content: space-between;
                color: $dark-grey;
                font-size: $main-text-size;
                background: $white;


                p {
                    background: $white;
                    margin-bottom: 0;
                }

                .amount {
                    color: $blue;
                    font-weight: 600;

                }
            }
        }

        .progress-wrapper {
            width: 100%;
            height: max-content;

            .progress {
                width: 100%;
                border-radius: 30px;
                border: 1px solid rgba(202, 202, 202, 0.50);
                background: $white;
                height: 14px;
                overflow: hidden;

                &-bar {
                    height: 100%;
                    transition: width 0.3s ease-in-out !important;
                    background: linear-gradient(107deg, #2A6DFF 0%, rgba(95, 145, 254, 0.98) 100%);
                }
            }
        }
    }

    .take-photo {
        @include center-objects;
        z-index: 999;
        margin: -3rem 0 2rem;
        background: $white;
        min-height: 55px;
        position: relative;
        border-radius: $main-border-radius;
        box-shadow: 0px -3px 25px 0px rgba(9, 13, 55, 0.15);
        
        button {
            width: 30%;
            color: $black;
    
            p {
                cursor: pointer;
                font-size: $small-text-size;
                background: $white;
            }
        }
    
        .active {
            font-weight: 600;
            color: $blue;
        }

        .inactive {
            color: $grey;
            font-weight: normal;

            p {
                cursor: default;
            }
        }

        &__wrapper {
            @include center-objects;
            background: $white;
            width: 55px;
            height: 55px;
            border-radius: 50%;

            img {
                cursor: pointer;
                border-radius: 50%;
                margin: -1.5rem 0 0 0;
            }
        }
    }

    .dropdown {
        justify-content: start;

        button {
            background-color: $blue;
            border-color: $blue;
        }
    }
}

// Add a media query for mobile devices
@media (max-width: 767px) {
    .predict {
        .video-container {
            height: auto;
        }

        .classes {
            margin-top: 1rem;
        }
    }
}