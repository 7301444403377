@import 'src/assets/styles/_variables.scss';

.train {
    max-width: 800px;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0.5rem;

    .panel {
        background: $white;
        border-radius: $main-border-radius + 2px; // Slightly increased border radius
        padding: 1rem;

        h3 {
            font-weight: 600;
            font-size: $headline-size;
            margin: 0 0 0.75rem;
            color: $black;
        }

        .description {
            color: $grey;
            font-size: $main-text-size;
            margin-bottom: 0.75rem;
        }
    }

    .checklist-panel {
        .rule {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 0.5rem;

            .check-wrapper {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                @include center-objects;

                &.wrong {
                    background-color: $light-red;
                    i { color: $red; }
                }

                i {
                    font-size: 12px;
                }
            }

            p {
                font-size: $main-text-size;
                color: $dark-grey;
            }
        }
    }

    .image-classes-panel {
        .classes {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            gap: 0.75rem;

            .class-item {
                display: flex;
                align-items: center;
                background: $ultra-light-grey;
                border-radius: $main-border-radius;
                padding: 0.5rem;

                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: $main-border-radius;
                    margin-right: 0.5rem;
                }

                &__info {
                    .name {
                        font-weight: 600;
                        font-size: $small-text-size;
                        color: $black;
                        margin: 0;
                    }

                    .amount-photo {
                        font-size: 0.75rem; // Changed from $extra-small-text-size
                        color: $grey;
                        margin: 0;
                    }
                }
            }
        }
    }

    .training-panel {
        .progress-wrapper {
            margin-bottom: 1rem;

            &__success {
                @include center-objects;
                img { max-width: 50px; }
            }

            &__number {
                font-size: $main-text-size;
                color: $blue;
                font-weight: 600;
            }

            .progress {
                height: 6px;
                border-radius: 3px;
                background: $ultra-light-grey;
                margin: 0.5rem 0;

                &-bar {
                    background: linear-gradient(90deg, $blue, lighten($blue, 20%));
                    border-radius: 3px;
                }
            }
        }
    }

    .model-actions-panel {
        .model-actions {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }
    }

    .navigation-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }

    button {
        padding: 0.75rem 1rem;
        font-size: $main-text-size;
        font-weight: 600;
        border-radius: $main-border-radius + 2px; // Slightly increased border radius
        transition: all 0.3s ease;
        border: none;
        cursor: pointer;
        width: 100%;

        &.primary {
            background: linear-gradient(90deg, $blue, lighten($blue, 10%));
            color: $white;

            &:hover {
                background: linear-gradient(90deg, darken($blue, 10%), $blue);
            }
        }

        &.secondary {
            background: $white;
            color: $blue;
            border: 2px solid $blue;

            &:hover {
                background: rgba($blue, 0.1);
            }
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    .checklist-and-classes-panel {
        .checklist-section {
            margin-bottom: 1.5rem;

            .rule {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 0.5rem;

                .check-wrapper {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    @include center-objects;

                    &.wrong {
                        background-color: $light-red;
                        i { color: $red; }
                    }

                    i {
                        font-size: 12px;
                    }
                }

                p {
                    font-size: $main-text-size;
                    color: $dark-grey;
                }
            }
        }

        .image-classes-section {
            .classes {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                gap: 0.75rem;

                .class-item {
                    display: flex;
                    align-items: center;
                    background: $ultra-light-grey;
                    border-radius: $main-border-radius;
                    padding: 0.5rem;

                    img {
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: $main-border-radius;
                        margin-right: 0.5rem;
                    }

                    &__info {
                        .name {
                            font-weight: 600;
                            font-size: $small-text-size;
                            color: $black;
                            margin: 0;
                        }

                        .amount-photo {
                            font-size: 0.75rem;
                            color: $grey;
                            margin: 0;
                        }
                    }
                }

                .no-classes-filler {
                    grid-column: 1 / -1; // Span all columns
                    background: $ultra-light-grey;
                    border-radius: $main-border-radius;
                    padding: 1rem;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    min-height: 120px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    transition: all 0.3s ease;

                    i {
                        font-size: 2rem;
                        color: $grey;
                        margin-bottom: 0.5rem;
                    }

                    p {
                        margin: 0;
                        color: $dark-grey;

                        &:first-of-type {
                            font-weight: 600;
                            font-size: $main-text-size;
                            margin-bottom: 0.25rem;
                        }

                        &:last-of-type {
                            font-size: $small-text-size;
                            color: $grey;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .train {
        padding: 0 1rem;
        gap: 1.5rem;

        .panel {
            padding: 1.5rem;
            // Removed box-shadow
        }

        button {
            width: auto;
            min-width: 150px;
            max-width: 250px;
        }

        .model-actions-panel .model-actions {
            flex-direction: row;
            justify-content: flex-start;
        }

        .navigation-buttons {
            margin-top: 1.5rem;
        }
    }
}

@media (min-width: 1024px) {
    .train {
        max-width: 900px;

        // Removed panel hover styles

        .image-classes-panel .classes {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
    }
}