@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css");

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  user-select: none;
  background-color: light-grey;
}

button {
  border: none;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}